/**
* green: #34b76e
* jaune: #FBBC05
 */
//@ignore lint
@import "node_modules/ng-zorro-antd/ng-zorro-antd.less";

@font-family: "Poppins", sans-serif;
@body-background: #f8f8f8;

@primary-color: #34b76e;
@success-color: #34b76e;
@danger-color: #eb4335;
@yellow-base: #fbbc05;

@border-radius-base: 5px;

@pagination-item-bg: #fff; //rgba(47, 128, 237, 0.2);
@pagination-item-link-bg: #fff; //rgba(47, 128, 237, .2);
@pagination-item-bg-active: rgba(47, 128, 237, 0.2);

@modal-header-bg: #4caf5019;
@layout-sider-background-light: @body-background;
@layout-body-background: @body-background;

.ant-collapse-header {
  background: rgba(76, 175, 80, 0.1);
}

.ant-modal-close-x {
  .ant-modal-close-icon {
    background: #b7b7b7;
    padding: 0.3rem;
    color: white;
    border-radius: 100px;
  }
}

.ant-form-item-label {
  font-family: "Kufam", sans-serif;
  color: #1e1e1e;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  //line-height: normal;
}

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  box-shadow: none !important;
}

.ant-tabs-tab {
  font-family: "Barlow", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

.ant-tabs-tab {
  margin-bottom: 0 !important;
  //padding-bottom: 0 !important;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom-width: 3px;
}

nz-date-picker,
.ant-picker {
  width: 100%;
}

.ant-pagination-options {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: @pagination-item-bg !important;
    border: none !important;
  }
}

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #000000;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  //color: rgba(255, 255, 255, 0.5);
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  color: #000000;

  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-item {
  border-radius: 100px;
}

.ant-pagination-item-active:hover a {
}

.ant-pagination-item-active a {
  color: rgba(47, 128, 237, 1);
}

.ant-pagination-item-active {
  border-color: rgba(47, 128, 237, 1);
}

.ant-pagination.mini .ant-pagination-options {
  margin-right: 1rem;
}

.ant-pagination.mini .ant-pagination-total-text {
  float: left;
  margin-left: 1rem;
}

.ant-pagination-options {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: @pagination-item-bg !important;
    border: none !important;
  }
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  color: rgba(47, 128, 237, 1);
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-item {
  border-radius: 100px;
}

nz-input-number-group,
nz-select {
  width: 100%;
}

.ant-input-suffix {
  border: none !important;
}

.weni-input-select {
  nz-select-top-control {
    border-radius: 50px !important;
    background: #fff;
    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.09);
    color: #000000;
  }
}

.weni-input-field-form,
.weni-input-field-form nz-select-top-control {
  border-radius: 12px !important;
  border: 1px solid #eaebec;
  background: #fff;
  color: #000000;

  &::placeholder {
    color: #cbcbcb;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
  }
}
